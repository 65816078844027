import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecordHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';

import {
  addCustomKeyboardShortcut,
  removeCustomKeyboardShortcut,
  resetCustomKeyboardShortcut,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/keyboardShortcuts';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import useGlobalStateWithFallback from '../../../../shared/foreground/utils/useGlobalStateWithFallback';
import { ShortcutData, ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import useDebounce from '../../../../shared/utils/useDebounce';
import { useAllShortcutsInUse, useShorcutsData } from '../../utils/shortcuts';
import { replaceBadKeyAliases } from '../../utils/shortcuts/aliasesWeReplace';
import { blackBoxKeys } from '../../utils/shortcuts/defaultsByLayout';
import { getDisplayShortcutKeys } from '../../utils/shortcuts/shortcuts';
import Button from '../Button';
import ShortcutsOptionsDropdown from '../Dropdown/ShortcutsOptionsDropdown';
import ResetIcon from '../icons/20StrokeReset';
import SearchIcon from '../icons/20StrokeSearch';
import AddIcon from '../icons/24SolidAdd';
import CloseIcon from '../icons/24StrokeClose';
import StrokeCancelIcon from '../icons/StrokeCancelIcon';
import { AccountSettingsPage } from './AccountSettingsPage';
import styles from './ShortcutsPage.module.css';

// TODO:
// - Fix when removing a default shortcut that has an alias it will remove both
// - Add custom shortcuts button in shortcuts CP

export const ShortcutsPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const shortcutsData = useShorcutsData();
  const allShortcutsInUse = useAllShortcutsInUse();
  const keyboardShortcutsBlackList = useGlobalStateWithFallback(
    [],
    useCallback((state) => state.persistent.keyboardShortcutsBlackList, []),
  );
  const customKeyboardShortcuts = useGlobalStateWithFallback(
    {},
    useCallback((state) => state.persistent.customKeyboardShortcuts, []),
  );

  const validateAndCreateNewShortcut = useCallback(
    ({ shortcutId, keys }: { shortcutId: ShortcutId; keys: string }) => {
      if (allShortcutsInUse.includes(keys)) {
        createToast({
          content: 'Shortcut already in use',
          category: 'error',
        });
      } else if (blackBoxKeys.includes(keys)) {
        createToast({
          content: 'Shortcut not allowed',
          category: 'error',
        });
      } else {
        addCustomKeyboardShortcut({ shortcutId, keys });
      }
    },
    [allShortcutsInUse],
  );

  return (
    <AccountSettingsPage
      additionalHeaderContent={
        <div className={styles.searchWrapper}>
          <SearchIcon className={styles.searchIcon} />
          <input
            aria-labelledby="search-label"
            type="search"
            autoComplete="off"
            placeholder="Find shortcuts..."
            onChange={(e) => setSearchQuery(e.target.value.toLocaleLowerCase())}
          />
        </div>
      }
      title="Keyboard shortcuts"
    >
      <div className={styles.headerWrapper}>
        <h1 className={styles.mainHeader}>
          <Link to="/preferences">Preferences /</Link> Keyboard shortcuts
        </h1>
        <div className={styles.layoutDropdownWrapper}>
          <ShortcutsOptionsDropdown />
        </div>
      </div>

      {Object.keys(shortcutsData).map((category) => (
        <ul className={styles.shortcutsList} key={category}>
          {!searchQuery && <li className={styles.categoryHeader}>{category}</li>}
          {Object.keys(shortcutsData[category]).map((shortcut) => {
            const shortcutData: ShortcutData = shortcutsData[category][shortcut];
            const isCustomShortcut =
              keyboardShortcutsBlackList.includes(shortcutData.id) ||
              Boolean(customKeyboardShortcuts[shortcutData.id]);

            if (searchQuery && !shortcutData.name.toLocaleLowerCase().includes(searchQuery)) {
              return null;
            }

            return (
              <ShortcutDetails
                key={shortcutData.id}
                shortcutData={shortcutData}
                isCustomShortcut={isCustomShortcut}
                validateAndCreateNewShortcut={validateAndCreateNewShortcut}
              />
            );
          })}
        </ul>
      ))}
    </AccountSettingsPage>
  );
};

const Shortcut = ({
  shortcutId,
  shortcut,
  isEditable = false,
}: { shortcut: string; isEditable?: boolean; shortcutId: ShortcutId }) => {
  const shortcutDisplayKeys = useMemo(() => getDisplayShortcutKeys(shortcut), [shortcut]);
  const keys = shortcutDisplayKeys.split(' ');

  const onRemove = useCallback(() => {
    removeCustomKeyboardShortcut({ shortcutId, keys: shortcut });
  }, [shortcutId, shortcut]);

  return (
    <div className={`${styles.shortcut} ${isEditable ? styles.isEditable : ''}`}>
      {keys.map((key) => (
        <div key={key} className={styles.key}>
          {replaceBadKeyAliases(key)}
        </div>
      ))}
      {isEditable && (
        <Button variant="unstyled" onClick={onRemove}>
          <StrokeCancelIcon />
        </Button>
      )}
    </div>
  );
};

const RecordingShortcut = ({ keys }: { keys: string }) => {
  if (keys) {
    return <Shortcut shortcutId={'recording' as ShortcutId} shortcut={keys} />;
  }

  return <div className={styles.recordShortcut}>Record shortcut...</div>;
};

const ShortcutDetails = ({
  shortcutData,
  isCustomShortcut,
  validateAndCreateNewShortcut,
}: {
  shortcutData: ShortcutData;
  isCustomShortcut: boolean;
  validateAndCreateNewShortcut: ({ shortcutId, keys }: { shortcutId: ShortcutId; keys: string }) => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [keysToSave, setKeysToSave] = useState(new Set<string>());
  const [recordedKeys, { start, stop, isRecording }] = useRecordHotkeys();

  const startRecordingKeys = useCallback(() => {
    start();
    window.isRecordingCustomShortcut = true;
  }, [start]);

  const stopRecordingKeys = useCallback(() => {
    stop();
    window.isRecordingCustomShortcut = false;
    setKeysToSave(new Set());
  }, [stop]);

  const formattedRecordedKeys = useMemo(() => {
    return Array.from(keysToSave).join('+').replaceAll('osleft', 'meta').replaceAll('osright', 'meta');
  }, [keysToSave]);

  useEffect(() => {
    if (recordedKeys) {
      setKeysToSave(recordedKeys);
    }
  }, [recordedKeys]);

  const debouncedRecordedKeys = useDebounce(formattedRecordedKeys, 1000);

  useEffect(() => {
    if (!isRecording || !debouncedRecordedKeys) {
      return;
    }

    stopRecordingKeys();
    validateAndCreateNewShortcut({ shortcutId: shortcutData.id, keys: debouncedRecordedKeys });
  }, [
    isRecording,
    debouncedRecordedKeys,
    stopRecordingKeys,
    shortcutData.id,
    validateAndCreateNewShortcut,
  ]);

  useEffect(() => {
    if (isRecording && keysToSave.has('escape')) {
      stopRecordingKeys();
    }
  }, [isRecording, keysToSave, stopRecordingKeys]);

  const keys = useMemo(() => shortcutData.keys, [shortcutData.keys]);

  const isEmpty = useMemo(() => !keys || !keys.length, [keys]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={`${styles.shortcutData} ${isHovered ? styles.isHovered : ''} ${
        isRecording ? styles.isRecording : ''
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.name}>{shortcutData.name}</div>
      <div className={styles.keys}>
        {isEmpty ? (
          isRecording ? (
            <RecordingShortcut keys={formattedRecordedKeys} />
          ) : (
            <Button variant="unstyled" onClick={startRecordingKeys} className={styles.emptyKeys}>
              None
            </Button>
          )
        ) : (
          <>
            {keys.map((shortcut) => (
              <Shortcut
                key={shortcut}
                shortcutId={shortcutData.id}
                shortcut={shortcut}
                isEditable={isHovered && !isRecording}
              />
            ))}
            {isRecording && <RecordingShortcut keys={formattedRecordedKeys} />}
          </>
        )}
      </div>
      {isRecording && (
        <div className={styles.actions}>
          <Button variant="unstyled" onClick={stopRecordingKeys}>
            <CloseIcon className={styles.resetIcon} />
          </Button>
        </div>
      )}
      {!isRecording && (
        <div className={styles.actions}>
          {isCustomShortcut && (
            <Button
              variant="unstyled"
              onClick={() => resetCustomKeyboardShortcut({ shortcutId: shortcutData.id })}
            >
              <ResetIcon className={styles.resetIcon} />
            </Button>
          )}
          <Button
            variant="unstyled"
            className={isHovered ? styles.visible : styles.hidden}
            onClick={startRecordingKeys}
          >
            <AddIcon className={styles.addIcon} />
          </Button>
        </div>
      )}
    </li>
  );
};
